import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Typography from '../components/common/Typography'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/SEO/SEO'

export default function Impressum() {
  const { t } = useTranslation()
  return (
    <>
      <SEO
        title="Pop Rocket | Impressum"
        description="Wir liefern Interaktionen, die begeistern. Gamification, motivierendes Design und maßgeschneiderte Entwicklung sorgen für unvergessliche digitale Erlebnisse."
        tabTitle="Impressum"
      />
      <Layout>
        <StyledImpressum>
          <StyledArticle>
            <StyledImpressumHeading type="h4">
              {t('imprint.title')}
            </StyledImpressumHeading>
            <StyledParagraph type="copyBig">
              Pop Rocket Labs GmbH
              <br />
              Gasstraße 14
              <br />
              22761 Hamburg
            </StyledParagraph>
            <br />
            <StyledParagraph type="copyBig">
              Email:&nbsp;
              <a href="mailto:info@poprocket.com?subject=Sch%C3%B6n,%20dass%20wir%20uns%20kennenlernen!">
                info@poprocket.com
              </a>
              <br />
              {t('imprint.phone')}: +49-40-6887 8690
            </StyledParagraph>
            <br />
            <StyledParagraph type="copyBig">
              {t('imprint.register')}: Amtsgericht Hamburg, HRB 114610
              <br />
              {t('imprint.management')}: Daryl Roske
            </StyledParagraph>
            <br />
            <StyledParagraph type="copyBig">
              {t('imprint.ust_number')}
            </StyledParagraph>
          </StyledArticle>

          <StyledArticle>
            <StyledImpressumHeading type="h4">
              {t('imprint.press_contact')}
            </StyledImpressumHeading>
            <StyledParagraph type="copyBig">
              {t('imprint.greeting')} Maayan Weiss
              <br />
              E-Mail:&nbsp;
              <a href="mailto:info@poprocket.com">info@poprocket.com</a>
            </StyledParagraph>
          </StyledArticle>
        </StyledImpressum>
      </Layout>
    </>
  )
}

const StyledImpressum = styled.section`
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 118rem;
  padding: 2.4rem;
  justify-content: start;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    flex-direction: row;
  }
`

const StyledImpressumHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.accent};
  margin: 0rem 0rem 2rem 0rem;
`
const StyledArticle = styled.article`
  margin: 2.4rem 0rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0rem 2rem;
  }
`

const StyledParagraph = styled(Typography)`
  line-height: 2.4rem;
`
